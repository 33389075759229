import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=23c99015&scoped=true&"
import script from "./Tabs.vue?vue&type=script&lang=ts&"
export * from "./Tabs.vue?vue&type=script&lang=ts&"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=23c99015&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c99015",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DropdownOrderby: require('/home/cleavr/www.bchollos.es/releases/20241011093225269/components/dropdown/Orderby.vue').default})
